'use strict';
import WOW from 'wow.js';
import Swiper from 'swiper/bundle';

let apiHost = 'https://www.piracanjuba.com.br';

// if (window.location.host != 'www.grupopiracanjuba.com.br') {
//     apiHost = 'https://piracanjuba.hml.base.digital';
// }

(function () {
    const mainNav = document.querySelectorAll('.main-nav .goto');

    const navigateTo = function (element) {
        element.addEventListener('click', (element) => {
            element.preventDefault();

            mainNav.forEach((element) => {
                element.classList.remove('active');
            });

            element.target.classList.add('active');

            window.history.pushState('', '', element.target.getAttribute('href').slice(1));

            document.querySelector('.main-nav').classList.remove('active');
            document.querySelector('.menu-hamburguer').classList.remove('active');

            window.scrollTo({
                behavior: 'smooth',
                top:
                    document.querySelector(`${element.target.getAttribute('href')}`).getBoundingClientRect().top -
                    document.body.getBoundingClientRect().top -
                    100,
            });
        });
    };

    document.querySelector('.logo')?.addEventListener('click', (element) => {
        navigateTo(element);
    });

    mainNav.forEach((element) => {
        navigateTo(element);
    });

    const windowLocation = window.location.pathname;

    if (
        windowLocation != '/' &&
        windowLocation != '/politica-cookies/' &&
        windowLocation != '/politica-privacidade/' &&
        windowLocation != '/termo-de-consentimento/' &&
        windowLocation != '/69-anos-piracanjuba/'
    ) {
        window.scrollTo({
            behavior: 'smooth',
            top:
                document.querySelector(`#${window.location.pathname.slice(1)}`).getBoundingClientRect().top -
                document.body.getBoundingClientRect().top -
                100,
        });

        document.querySelector(`.main-nav a[href="#${window.location.pathname.slice(1)}"]`).classList.add('active');
    }

    let lastScrollTop = 0;

    window.addEventListener(
        'scroll',
        function () {
            let st = window.pageYOffset || document.documentElement.scrollTop;
            if (st > lastScrollTop) {
                document.querySelector('header').classList.add('active');
            } else if (st < lastScrollTop) {
                document.querySelector('header').classList.remove('active');
            }
            lastScrollTop = st <= 0 ? 0 : st;
        },
        false
    );

    if (document.querySelector('.menu-hamburguer')) {
        document.querySelector('.menu-hamburguer').addEventListener('click', (element) => {
            element.target.classList.toggle('active');
            document.querySelector('.main-nav').classList.toggle('active');
        });
    }

    if (document.querySelector('.toggle-sanfona')) {
        document.querySelector('.toggle-sanfona').addEventListener('click', (element) => {
            document.querySelector('.sanfona').classList.add('active');
            element.target.classList.add('hidden');
        });
    }

    if (document.querySelector('.video-manifesto')) {
        document.querySelector('.video-manifesto').addEventListener('click', (element) => {
            document.querySelector('.modal').classList.add('open');
            document.getElementById('videoModal').setAttribute('src', element.target.dataset.video);
        });
    }

    if (document.querySelector('.video-marca')) {
        document.querySelector('.video-marca').addEventListener('click', (element) => {
            document.querySelector('.modal').classList.add('open');
            document.getElementById('videoModal').setAttribute('src', element.target.dataset.video);
        });
    }

    if (document.querySelector('.close-modal')) {
        document.querySelector('.close-modal').addEventListener('click', (element) => {
            document.querySelector('.modal').classList.remove('open');
            document.getElementById('videoModal').setAttribute('src', '');
        });
    }

    //LP 69 ANOS

    // Open Modal based on data-target
    if (document.querySelectorAll('.trigger-modal').length > 0) {
        document.querySelectorAll('.trigger-modal').forEach((el) => {
            el.addEventListener('click', () => {
                document.querySelector(`.${el.dataset.target}`).classList.add('open');
                document.body.style.overflow = 'hidden';
                document.querySelector('#backdrop').classList.remove('hidden');
            });
        });
    }

    // Close Modal based on data-target
    if (document.querySelectorAll('.close-modal').length > 0) {
        document.querySelectorAll('.close-modal').forEach((el) => {
            el.addEventListener('click', () => {
                document.querySelector(`.${el.dataset.target}`).classList.remove('open');
                document.body.style.overflow = 'auto';
                document.querySelector('#backdrop').classList.add('hidden');
                window.history.pushState('', document.title, window.location.pathname + window.location.search);
            });
        });
    }

    // Se tiver #testimonial na URL, abre o modal de depoimento
    if (window.location.hash != '') {
        if (window.location.hash === '#testimonial') {
            document.querySelector('.modal-testimonial').classList.add('open');
            document.body.style.overflow = 'hidden';
            document.querySelector('#backdrop').classList.remove('hidden');
        } else {
            let testimonialId = window.location.hash.split('#')[1];
            let modal = document.querySelector('.modal-testimonial-info');
            modal.classList.add('open');
            document.body.style.overflow = 'hidden';
            document.querySelector('#backdrop').classList.remove('hidden');

            fetch(apiHost + '/api/lp_testimonials/' + testimonialId, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            })
                .then((response) => response.json())
                .then((response) => {
                    testimonial(response);
                    populateCarousel(response.unit.slice(-2));
                })
                .then(() => {
                    setTimeout(() => {
                        modal.querySelector('.loader').classList.add('fadeOut');
                    }, 1000);

                    setTimeout(() => {
                        modal.querySelector('.loader').classList.add('hidden');
                        modal.querySelector('.loader').classList.remove('fadeOut');
                    }, 2000);
                })
                .catch((err) => console.error(err));
        }
    }

    if (document.querySelector('#equalizer')) {
        const equalizer = document.querySelector('#equalizer');
        const audio = equalizer.querySelector('#audio');
        const equalizerOn = equalizer.querySelector('.equalizer-on');
        const equalizerOff = equalizer.querySelector('.equalizer-off');

        document.addEventListener(
            'click',
            (e) => {
                audio.play();
                equalizer.classList.add('started');
            },
            { once: true }
        );

        let randomHeight = () => {
            return Math.random() * (22 - 4) + 4;
        };

        let animateBars = (bars) => {
            equalizerOn.querySelectorAll('.bar').forEach((bar) => {
                bar.setAttribute('y2', 22 - randomHeight());
            });
        };

        setInterval(animateBars, 300);

        equalizer.addEventListener('click', () => {
            if (equalizer.classList.contains('started')) {
                equalizerOn.classList.toggle('hidden');
                equalizerOff.classList.toggle('hidden');
            } else {
                equalizer.classList.add('started');
            }

            audio.paused ? audio.play() : audio.pause();
        });
    }

    if (document.querySelector('.last-testimonials')) {
        lastTestimonials();
    }

    if (document.querySelector('.select-field')) {
        document.querySelector('.select-field select').addEventListener('change', (element) => {
            const select = document.querySelector('.select-field select');
            document.querySelector('.select-field span').innerHTML = select.options[select.selectedIndex].text;
        });
    }

    if (document.querySelector('.modal-testimonial')) {
        loadUnities();
        const modalTestimonials = document.querySelector('.modal-testimonial');

        modalTestimonials.querySelector('form').addEventListener('submit', (e) => {
            e.preventDefault();

            grecaptcha.ready(() => {
                grecaptcha.execute('6Ld7rlUlAAAAAA3L4-cl1m52hrolL9CKiZFTuXrZ', { action: 'submit' }).then((token) => {
                    document.getElementById('g-recaptcha-response').value = token;

                    const form = new FormData(modalTestimonials.querySelector('form'));

                    let isValid = false;
                    document.querySelectorAll('form .required').forEach((el) => {
                        if (validate(el) == 6) {
                            isValid = true;
                        }
                    });

                    if (isValid) {
                        const options = {
                            method: 'POST',
                            headers: {
                                Accept: 'application/json',
                            },
                            body: form,
                        };

                        fetch(apiHost + '/api/lp_testimonials', options)
                            .then((response) => response.json())
                            .then((response) => {
                                modalTestimonials.classList.add('form-sended');
                            })
                            .catch((err) => console.error(err, form));
                    }
                });
            });
        });
    }

    if (document.querySelector('.upload-photo')) {
        document.querySelector('.upload-photo .upload').addEventListener('change', (element) => {
            const fileName = document.querySelector('.upload-photo .upload').files[0].name;
            document.querySelector('.upload-photo span').innerHTML = fileName;
        });
    }

    if (document.querySelector('.back')) {
        document.querySelector('.back').addEventListener('click', () => {
            document.querySelector('.text-no-filter').classList.remove('hidden');
            document.querySelector('.text-filter').classList.add('hidden');
            document.querySelector('.back').classList.add('hidden');
            lastTestimonials();
        });
    }

    if (document.querySelector('.pin')) {
        document.querySelectorAll('.pin').forEach((el) => {
            el.addEventListener('click', () => {
                let lt = document.querySelector('.last-testimonials');
                lt.dataset.state = el.getAttribute('data-state');
                lt.dataset.page = 1;
                filterTestimonials(lt.dataset.page, lt.dataset.state);
            });
        });
        document.querySelector('.pagination .next').addEventListener('click', () => {
            let lt = document.querySelector('.last-testimonials');
            lt.dataset.page = ++lt.dataset.page;
            filterTestimonials(lt.dataset.page, lt.dataset.state);
        });

        document.querySelector('.pagination .prev').addEventListener('click', () => {
            let lt = document.querySelector('.last-testimonials');
            lt.dataset.page = --lt.dataset.page;
            filterTestimonials(lt.dataset.page, lt.dataset.state);
        });
    }

    new WOW().init();
})();

function validate(field) {
    const fieldContainer = field.parentElement;

    if (field.getAttribute('type') == 'checkbox') {
        if (field.checked) {
            field.classList.add('valid');
            field.classList.remove('error');
        } else {
            field.classList.remove('valid');
            field.classList.add('error');
        }
    }

    if (!field.value && field.getAttribute('type') != 'checkbox') {
        fieldContainer.querySelector('.error').classList.remove('hidden');
        if (fieldContainer.classList.contains('valid')) {
            fieldContainer.classList.remove('valid');
        }

        return 0;
    } else if (field.getAttribute('type') != 'checkbox') {
        fieldContainer.classList.add('valid');
        fieldContainer.querySelector('.error').classList.add('hidden');
        return document.querySelectorAll('.valid').length;
    }
}

function modalTestimonialInfo() {
    const options = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
    };
    document.querySelectorAll('.thumb-testimonial').forEach((el) => {
        el.addEventListener('click', () => {
            const id = el.getAttribute('data-id');
            const modal = document.querySelector('.modal-testimonial-info');
            modal.querySelector('.loader').classList.remove('hidden');
            modal.classList.add('open');
            document.body.style.overflow = 'hidden';
            document.querySelector('#backdrop').classList.remove('hidden');

            fetch(apiHost + '/api/lp_testimonials/' + id, options)
                .then((response) => response.json())
                .then((response) => {
                    testimonial(response);
                    populateCarousel(response.unit.slice(-2));
                })
                .then(() => {
                    setTimeout(() => {
                        modal.querySelector('.loader').classList.add('fadeOut');
                    }, 1000);

                    setTimeout(() => {
                        modal.querySelector('.loader').classList.add('hidden');
                        modal.querySelector('.loader').classList.remove('fadeOut');
                    }, 2000);
                })
                .catch((err) => console.error(err));
        });
    });
}

function populateCarousel(state) {
    const modal = document.querySelector('.modal-testimonial-info');
    modal.querySelector('.swiper-wrapper').innerHTML = '';

    const options = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
    };

    fetch(apiHost + '/api/lp_testimonials/state/' + state, options)
        .then((response) => response.json())
        .then((response) => {
            if (response.length > 0) {
                response.forEach((el) => {
                    modal
                        .querySelector('.swiper-wrapper')
                        .insertAdjacentHTML(
                            'afterbegin',
                            `<div class="swiper-slide"><img class="rounded-full m-auto max-w-[75px] max-h-[75px] w-full h-full border-2 border-orange-two cursor-pointer object-cover" data-id="${el.id}" src="${el.image}" /></div>`
                        );
                });

                // init Swiper
                const swiper = new Swiper('.modal-testimonial-info .carousel', {
                    loop: false,
                    slidesPerView: 3,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    breakpoints: {
                        768: {
                            slidesPerView: 8,
                        },
                        500: {
                            slidesPerView: 6,
                        },
                    },
                });

                modal.querySelectorAll('.swiper-slide img').forEach((el) => {
                    el.addEventListener('click', () => {
                        const id = el.getAttribute('data-id');
                        modal.querySelector('.loader').classList.remove('hidden');

                        fetch(apiHost + '/api/lp_testimonials/' + id, options)
                            .then((response) => response.json())
                            .then((response) => {
                                testimonial(response);
                            })
                            .then(() => {
                                setTimeout(() => {
                                    modal.querySelector('.loader').classList.add('fadeOut');
                                }, 1000);

                                setTimeout(() => {
                                    modal.querySelector('.loader').classList.add('hidden');
                                    modal.querySelector('.loader').classList.remove('fadeOut');
                                }, 2000);
                            })
                            .catch((err) => console.error(err));
                    });
                });
            }
        })
        .catch((err) => console.error(err));
}

function testimonial(response) {
    const modal = document.querySelector('.modal-testimonial-info');

    modal.querySelector('.unit').innerHTML = response.unit;
    modal.querySelector('.name').innerHTML = response.name;
    modal.querySelector('.role').innerHTML = response.job_role;
    modal.querySelector('.testimonial').innerHTML = response.text;
    modal.querySelector('.photo img').setAttribute('src', response.image);
    modal
        .querySelector('.whatsapp')
        .setAttribute(
            'href',
            `https://api.whatsapp.com/send?text=A Piracanjuba está há 69 anos promovendo histórias e vidas! Confira o depoimento de ${response.name} - ${apiHost}/69-anos-piracanjuba/share/${response.id}`
        );
    modal
        .querySelector('.facebook')
        .setAttribute(
            'href',
            `https://www.facebook.com/sharer/sharer.php?u=${apiHost}/69-anos-piracanjuba/share/${response.id}&description=A Piracanjuba está há 69 anos promovendo histórias e vidas! Confira o depoimento de ${response.name}`
        );
    modal
        .querySelector('.linkedin')
        .setAttribute(
            'href',
            `https://www.linkedin.com/sharing/share-offsite/?url=${apiHost}/69-anos-piracanjuba/share/${response.id}&text=A Piracanjuba está há 69 anos promovendo histórias e vidas! Confira o depoimento de ${response.name}`
        );
    modal
        .querySelector('.x')
        .setAttribute(
            'href',
            `http://twitter.com/share?text=A Piracanjuba está há 69 anos promovendo histórias e vidas! Confira o depoimento de ${response.name}&url=${apiHost}/69-anos-piracanjuba/share/${response.id}`
        );
}

function filterTestimonials(page, state) {
    const lt = document.querySelector('.last-testimonials');
    lt.querySelector('.loader').classList.remove('hidden');

    const options = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
    };

    let url;
    if (state) {
        url = `${apiHost}/api/lp_testimonials?state=${state}&page=${page}`
    } else {
        url = `${apiHost}/api/lp_testimonials?page=${page}`
    }

    fetch(url, options)
        .then((response) => response.json())
        .then((response) => {
            document.querySelector('.list-last-testimonials').innerHTML = '';
            if (response.last_page == 1) {
                document.querySelector('.pagination').classList.add('hidden');
            } else {
                document.querySelector('.pagination').classList.remove('hidden');
                document.querySelector('.pagination').classList.add('flex');
            }
            if (response.current_page == 1) {
                document.querySelector('.pagination .prev').classList.add('hidden');
            } else {
                document.querySelector('.pagination .prev').classList.remove('hidden');
            }
            if (!response.next_page_url) {
                document.querySelector('.pagination .next').classList.add('hidden');
            } else {
                document.querySelector('.pagination .next').classList.remove('hidden');
            }

            if (state) {
                let stateName;
                if (state == 'GO') {
                    stateName = 'Goiás';
                } else if (state == 'MG') {
                    stateName = 'Minas Gerais';
                } else if (state == 'RJ') {
                    stateName = 'Rio de Janeiro';
                } else if (state == 'SP') {
                    stateName = 'São Paulo';
                } else if (state == 'PR') {
                    stateName = 'Paraná';
                } else if (state == 'SC') {
                    stateName = 'Santa Catarina';
                } else if (state == 'RS') {
                    stateName = 'Rio Grande do Sul';
                }
                document.querySelector('.text-filter .state-name').innerHTML = stateName;
            }

            if(state){
                document.querySelector('.text-no-filter').classList.add('hidden');
                document.querySelector('.text-filter').classList.remove('hidden');
                document.querySelector('.back').classList.remove('hidden');
            }

            document.querySelector('.pagination .current-page').innerHTML = page;
            document.querySelector('.pagination .total-pages').innerHTML = response.last_page;
            response.data.forEach((item) => {
                document
                    .querySelector('.list-last-testimonials')
                    .insertAdjacentHTML(
                        'afterbegin',
                        `<img class="thumb-testimonial cursor-pointer max-w-[75px] max-h-[75px] w-full h-full rounded-full border-2 border-orange-two object-cover" data-id="${item.id}" src="${item.image}">`
                    );
            });
            modalTestimonialInfo();

            setTimeout(() => {
                lt.querySelector('.loader').classList.add('fadeOut');
            }, 1000);

            setTimeout(() => {
                lt.querySelector('.loader').classList.add('hidden');
                lt.querySelector('.loader').classList.remove('fadeOut');
            }, 2000);
        })
        .catch((err) => console.error(err));
}

function lastTestimonials() {
    const lt = document.querySelector('.last-testimonials');
    lt.querySelector('.loader').classList.remove('hidden');
    document.querySelector('.list-last-testimonials').innerHTML = '';
    filterTestimonials(1);
}

function loadUnities() {
    const modal = document.querySelector('.modal-testimonial');

    const options = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
    };

    fetch(apiHost + '/api/units', options)
        .then((response) => response.json())
        .then((response) => {
            if (response.length > 0) {
                response.forEach((el) => {
                    modal
                        .querySelector('select')
                        .insertAdjacentHTML('beforeend', `<option value="${el.id}">${el.name}</option>`);
                });
            }
        })
        .catch((err) => console.error(err));
}
